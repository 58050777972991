import vue from 'vue'

import { eduUrl } from '@/config/config'

const http = vue.prototype.http

export default {

  async getAdCode () {
    return await http.get(eduUrl + '/api/edu/organizations/user-area-id')
  },

  // 获取系统默认某款
  async getSystemDefaultModule () {
    const res = await http.post('/district-command-center/settings/getTemplate')
    if (res.status === 200) {
      // eslint-disable-next-line no-return-assign
      res.data.forEach((item, index) => item.orderNum = index)
    }
    return res
  },

  async getBaseStatistics () {
    return await http.get('/district-command-center/city/dataStats/baseInfoData')
  },

  // 实时统计
  async getRealTimeStatistics () {
    return await http.get('/district-command-center/city/dataStats/inSchoolData')
  },

  // 宿舍管理
  async getDorm () {
    return await http.get('/district-command-center/city/dataStats/dormData')
  },

  // 城市基本数据
  async getCityStatistics () {
    return await http.get('/district-command-center/city/dataStats/baseInfoData')
  },

  // 体温监测
  async getTemperatureData () {
    return await http.get('/district-command-center/city/dataStats/temperatureData')
  },

  // 考勤
  async getStudentAttendance () {
    return await http.get('/district-command-center/city/dataStats/studentCheckData')
  },

  // 报警
  async getAlarmInfoData () {
    return await http.get('/district-command-center/city/dataStats/alarmInfoData')
  },

  // 云上党建
  async getPartyBuild () {
    return await http.get('/district-command-center/city/dataStats/partyBuildData')
  },

  // 在校数据详情
  async getInSchoolDetail () {
    return await http.get('/district-command-center/city/dataStatsDetail/inSchoolDataDetail')
  },

  // 体温异常详情
  async getTemperatureDetail () {
    return await http.get('/district-command-center/city/dataStatsDetail/temperatureDetail')
  },

  // 报警详情
  async getAlarmInfoDetail () {
    return await http.get('/district-command-center/city/dataStatsDetail/alarmInfoDetail')
  }
}
