<template>
  <div class="container">
    <div style="width: 2rem;height: 500px;overflow: hidden;" ref="wrapper">
      <div ref="data">
        <div v-for="i in 10" :key="i"
             style="width: 1.52rem;height: 0.86rem;padding-left: 20.04rem;background: rgba(1, 19, 67, 0.5); border-radius: 0.05rem;padding-top: 0.1rem;margin: 0.1rem auto">
          <p style="color: #FFA800;font-weight: bold">鼓楼区</p>
          <p style="color: #FFA800;font-weight: bold;margin: 0.1rem 0;height: 0.16rem;line-height: 0.16rem">
            <img src="/static/images/student.png"
                 style="width: 0.16rem;height: 0.16rem;display: block;float: left;margin-right: 0.06rem"/>
            <span style="color: #B1D9FF">学生：</span>
            <span style="color: #FFFFFF;font-weight: bold;font-size: 0.14rem">123123</span>
          </p>
          <p style="color: #FFA800;font-weight: bold;height: 0.16rem;line-height: 0.16rem">
            <img src="/static/images/teacher.png"
                 style="width: 0.16rem;height: 0.16rem;display: block;float: left;margin-right: 0.06rem"/>
            <span style="color: #B1D9FF">学生：</span>
            <span style="color: #FFFFFF;font-weight: bold;font-size: 0.14rem">123123</span>
          </p>
        </div>
      </div>
      <div ref="copy"></div>
    </div>

    <div style="width: 100%;height:100%">
      <distribute ref="distribute" height="100%"></distribute>
    </div>
  </div>
</template>
<script>
import api from '../../api/cityDashboardData'

export default {

  name: 'city',

  components: {
    distribute: () => import('./components/distributeDev')
  },
  data () {
    return {
      showDialog: false,
      tabList: [
        { label: '在校数据', name: '' },
        { label: '体温异常', name: '' },
        { label: '校园报警', name: '' }
      ],
      districtSearch: null,
      districtBoundaries: {},
      districtTotal: 0,
      districtLoadLeft: 0
    }
  },

  updated () {
    setTimeout(() => {
      this.$refs.distribute.init()
    }, 1000)
    this.startMarquee()
  },

  methods: {
    startMarquee () {
      const wrapper = this.$refs.wrapper
      const data = this.$refs.data
      const copy = this.$refs.copy
      copy.innerHTML = data.innerHTML
      wrapper.scrollTop = wrapper.scrollHeight
      // setInterval(() => {
      //   // if (data.offsetTop - wrapper.scrollTop >= 0)
      //   //   wrapper.scrollTop += copy.offsetHeight
      //   // else {
      //   //   wrapper.scrollTop--
      //   // }
      //   if (copy.offsetTop - wrapper.scrollTop <= 0)    //当滚动至demo1与demo2交界时
      //     wrapper.scrollTop -= data.offsetHeight    //demo跳到最顶端
      //   else {
      //     wrapper.scrollTop++
      //   }
      // }, 60);
    }
  }
}
</script>
<style scoped>
.container {
  height: 100%;
  overflow: hidden;
  position: relative;
  background-image: url(../../assets/img/body.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
